import React from "react"

import componentStyles from "./oilCan.module.scss"

const OilCan = () => (
    <svg viewBox="0 0 600 600">
        <g id="canBody">
            <path className={componentStyles.whiteFill} d="M95.42,270.91C78.66,302,66.69,326.77,57.12,345.12s19.15,32.72,29.52,13.57S143.3,248.57,143.3,248.57,112.18,239.79,95.42,270.91Z"/>
            <path className={componentStyles.whiteFill} d="M478.7,122.5c-22.38,0-55.32-2.13-120.22,25.53s-87.25,72.35-94.69,72.35c-2.5,0-5.35-2.52-8.41-6.42H221.21l0,0H135.05c-46.81,0-67,37.24-67,37.24l-35.11,69s-30.85,53.35,16,74.63,67-20.21,67-20.21,37.24-77.67,54.26-106.4,52.13-23.4,52.13-23.4v29.79h87.25V239.53s35-39.88,65.9-58S450,148,473.38,149.09,501.08,122.5,478.7,122.5ZM86.64,358.69c-10.37,19.15-39.1,4.79-29.52-13.57S78.66,302,95.42,270.91s47.88-22.34,47.88-22.34S97,339.54,86.64,358.69Z"/>
            <path className={componentStyles.whiteFill} d="M311.66,292.46H219.1s-67.94,24.74-67.94,52.66V527s-1.6,13.57,9.57,13.57H370c12,0,12.77-14.36,12.77-14.36V348.32C382.8,318.79,311.66,292.46,311.66,292.46Zm49.48,180s.59-103.28-12.1-121c-14.75-20.58-35.7-34.82-35.7-34.82s41.61,11.51,47.8,32.76S361.14,472.44,361.14,472.44Z"/>
        </g>
        <g id="lever" className={`${componentStyles.whiteFill} ${componentStyles.lever}`}>
            <path d="M236.13,183.14c-9.58-14.9-18.09-21.28-33-20.21s-45.75-5.32-56.39-14.9-19.15-19.15-27.66-11.7,9.58,30.85,38.3,44.68,48.94,13.83,48.94,13.83L221.21,214s6.43,18.61,20.3,18.61S255.38,214,255.38,214C249.33,206.23,242.49,193,236.13,183.14Z"/>
        </g>
        <g id="spray">
            <line className={componentStyles.spray} x1="512.27" y1="134.89" x2="584.17" y2="134.89"/>
            <line className={componentStyles.spray} x1="512.31" y1="164.21" x2="566.13" y2="211.87"/>
            <line className={componentStyles.spray} x1="511.68" y1="104.99" x2="562.76" y2="54.39"/>
        </g>
    </svg>
)

export default OilCan